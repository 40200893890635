/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import chatStoreModule from '@/views/common/chat/chatStoreModule'
// import createPlugin from 'logrocket-vuex'
// import LogRocket from 'logrocket'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import network from './network'
import notification from './notification'
import problem from './problem'
import studentInteractions from './student-interactions'
import studentLab from './student-lab'
import authEvents from './events/auth'
import studentNotes from './student-notes/index'
import appDeployment from './events/app-deployment/index'
import session from './events/session/index'
import taskHelper from './task-helper'
import reaStore from './rea'

// LogRocket.init('42jtg7/react')
// const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    network,
    verticalMenu,
    notification,
    problem,
    studentInteractions,
    authEvents,
    appDeployment,
    studentNotes,
    session,
    studentLab,
    taskHelper,
    reaStore,
    'app-chat': chatStoreModule,
  },
  // plugins: [logrocketPlugin],

})
