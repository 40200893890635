<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >

    <unauthorized v-if="notAuthorized" />

    <component
      :is="layout"
      v-else
    />

    <student-video />

    <!-- all sticky buttons except chat icon -->
    <sticky-icons
      v-if="isStudent || isTeacher"
    />
    <call-info-modal-vue
      v-if="callModalOpen"
      :incoming-call-message-payload="incomingCallMessagePayload"
    />
    <device-seletion
      v-if="deviceSelctionOpen"
      only-for-audio="deviceSelectionOnlyForAudio"
    />

    <interactive-avatar v-if="selectedCharacter" />

    <camera-permission-modal v-if="loggedIn" />

    <div
      v-if="isAStudent && themeOn && largeDevices"
      class="background-animation"
      :style="`background:url('/animations/themes/forest/sky.png'); background-repeat:no-repeat; background-size:cover;  width: 100%; height: 100%; position: fixed; top:0%; left:0%; z-index:-10`"
    >

      <div
        v-if="currentTheme=='forest'"
        id="clouds"
      />

      <animation-modal
        :animation-file="allThemes[currentTheme].file"
        :animation-types="{idle:0}"
        :active-animation="['idle']"
        canvas-id="webgl-ground"
        position="fixed"
        :canvas-height="windowHeight"
        :canvas-width="windowWidth"
        top="0%"
        left="0"
        :camera-position="{x:0,y:1,z:2}"
        :scaling="{x:1,y:1,z:1}"
        :light-intensity="4"
        :play-all-animation="true"
      />

      <animation-modal
        v-if="currentTheme==='forest'"
        :animation-file="allThemes[currentTheme].character.file"
        :animation-types="{idle:0,swing:1,blink:2}"
        :active-animation="['idle','blink']"
        canvas-id="webgl-monkey"
        :scaling="{x:1,y:1,z:1}"
        :camera-position="{x:1,y:0,z:2}"
        :light-intensity="3"
        position="fixed"
        top="0%"
        left="-3%"
      />

    </div>
    <portal-target name="dragModalTargetArea"
                   multiple
    />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch } from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'
import StudentVideo from '@views/student/CaptureVideo.vue'
import {
  VaniEvent,
} from 'vani-meeting-client'
import store from '@/store'
import {
  getClassroomSettings, engagementSettings,
} from '@/utils/visibilitySettings'
import CallInfoModalVue from '@/views/common/chat/call/CallInfoModal.vue'
import DeviceSeletion from '@/views/common/chat/call/DeviceSelection.vue'
import ChatHandler from '@/views/common/chat/ChatHandler'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT, USER_TYPE_TEACHER } from '@/const/userType'
import Unauthorized from '@/views/Unauthorized.vue'
import useJwt from '@/auth/jwt/useJwt'
import CameraPermissionModal from '@/views/common/permissions/CameraPermissionModal.vue'
// import LogRocket from 'logrocket'
import GEC_EVENT_BUS from '@/utils/eventBus'
import StickyIcons from '@/views/common/sticky-icons/Index.vue'
import { THEMES } from './const/theme'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutGuest = () => import('@/layouts/guest/LayoutGuest.vue')
const ClassRoomLayout = () => import('@/layouts/classroom-layout/ClassRoomLayout.vue')
const InteractiveAvatar = () => import('@/layouts/components/InteractiveAvatar.vue')
const AnimationModal = () => import('@/layouts/components/AnimationModal.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutGuest,
    LayoutVertical,
    LayoutFull,
    InteractiveAvatar,
    Unauthorized,
    StudentVideo,
    CallInfoModalVue,
    DeviceSeletion,
    AnimationModal,
    CameraPermissionModal,
    StickyIcons,
    ClassRoomLayout,
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
    else if (skin.value === 'semi-light') document.body.classList.add('semi-light-layout')
    else if (skin.value === 'brown') document.body.classList.add('brown-layout')
    else if (skin.value === 'green') document.body.classList.add('green-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  data() {
    return {
      callModalOpen: false,
      incomingCallMessagePayload: undefined,
      deviceSelctionOpen: false,
      deviceSelectionOnlyForAudio: false,
      isWindowActive: true,
      windowWidth: window.innerWidth,
      forestAnimationTypes: {
        idle: 0,
      },
      windowHeight: window.innerHeight,
      allThemes: THEMES,
      notAuthorized: false,
      isStudent: getUserData() && getUserData().usertype === USER_TYPE_STUDENT,
      isTeacher: getUserData() && getUserData().usertype === USER_TYPE_TEACHER,
      loggedIn: getUserData(),
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'guest') return 'layout-guest'
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'classRoom') return 'ClassRoomLayout'
      return `layout-${this.contentLayoutType}`
    },
    isStudentYoung() {
      return this.$store.getters['appConfig/getIsStudentYoung']
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    isAStudent() {
      return this.isStudent
    },
    themeOn() {
      const themeValue = this.$store.state.appConfig.themeOn
      if (themeValue === 'false' || themeValue === false) {
        return false
      }
      return true
    },
    selectedCharacter() {
      return this.$store.getters['appConfig/currentCharacter']
    },
    currentTheme() {
      return this.$store.getters['appConfig/currentTheme']
    },

    largeDevices() {
      return window.innerWidth > 1068
    },

  },

  watch: {
    isStudentYoung() {
      if (this.isStudentYoung) {
        document.body.classList.add('small-kid')
      } else {
        document.body.classList.remove('small-kid')
      }
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    this.$store.dispatch('session/start')
    ChatHandler.getInstance().getMeetingHandler().getEventEmitter().on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessageReceived)
    ChatHandler.getInstance().eventEmitter.on('HideVideoLayout', this.hideCallModel)
    ChatHandler.getInstance().eventEmitter.on('HideAudioVideoSelector', this.hideAudioVideoSelector)
    ChatHandler.getInstance().eventEmitter.on('ShowAudioVideoSelector', this.showAudioVideoSelector)
    if (localStorage.getItem('userData')) {
      this.updateUserActiveTime()
    }
    if (this.isAStudent) {
      this.getCurrentStudentCharacter()
      GEC_EVENT_BUS.$on('schoolDistrictConfigReady', () => {
        this.getSchoolSettings()
      })
    }
    window.addEventListener('focus', () => {
      this.isWindowActive = true
    })
    window.addEventListener('blur', () => {
      this.isWindowActive = false
    })
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('company')) {
      this.initCompanyLogo(urlParams.get('company'))
    } else if (this.$store.state.app.partnerCompanyInfo) {
      this.setCompanyLogo(this.$store.state.app.partnerCompanyInfo)
    } else {
      this.initCompanyLogo('by-domain')
    }
    GEC_EVENT_BUS.$on('loggedin', () => {
      this.isStudent = getUserData() && getUserData().usertype === USER_TYPE_STUDENT
      this.isTeacher = getUserData() && getUserData().usertype === USER_TYPE_TEACHER
      this.loggedIn = true
      this.getSchoolSettings()
    })

    window.onresize = () => {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }

    if (this.isStudentYoung) {
      document.body.classList.add('small-kid')
    }
  },
  methods: {
    setCompanyLogo(logo, saveToStore = false) {
      if (logo) {
        this.$store.commit('app/SET_COMPANY_LOGO', logo)
        document.title = logo.name || 'Global English Campus'
        const links = document.querySelectorAll("link[rel~='icon']")
        if (logo.fav_icon) {
          links.forEach(link => {
            // eslint-disable-next-line no-param-reassign
            link.href = logo.fav_icon
          })
        } else {
          links.forEach(link => {
            // eslint-disable-next-line no-param-reassign
            link.href = logo.logo
          })
        }
        if (saveToStore) {
          this.$store.commit('app/SET_COMPANY_INFORMATION')
        }
      }
    },
    // TODO: Monkey position is not clear
    // playMonkey() {
    //   const audio = new Audio('/animations/animals/monkey.mp3')
    //   audio.play()
    // },
    initCompanyLogo(company) {
      useJwt.getCompanyLogo(company).then(response => {
        if (response.data.logo) {
          this.setCompanyLogo(response.data.logo)
        } else if (localStorage.getItem('userData')) {
          this.setCompanyLogo({
            logo: '/img/logo.023152ca.svg',
            nav_icon: '/img/new-logo.5e5f5955.svg',
          }, true)
        }
      }).catch(() => {
        this.setCompanyLogo({
          logo: '/img/logo.023152ca.svg',
          name: 'Global English Campus',
          nav_icon: '/img/new-logo.5e5f5955.svg',
        })
      })
    },
    updateUserActiveTime() {
      if (localStorage.getItem('userData')) {
        const interval = 120
        setTimeout(() => {
          if (!this.isWindowActive) {
            this.updateUserActiveTime()
            return
          }
          useJwt.recordUserTimeSpent({
            params: {
              spend_time: interval,
            },
          }).finally(() => { this.updateUserActiveTime() })
        }, interval * 1000)
      }
    },
    onNewChatMessageReceived(messagePayload) {
      if (messagePayload.type === 'VideoCall') {
        if (window.isUserBusyOnCall) {
          ChatHandler.getInstance().eventEmitter.emit('UserIsVacentOnCall', messagePayload)
          return
        }
        this.incomingCallMessagePayload = messagePayload
        window.incomingCallMessagePayload = messagePayload
        this.callModalOpen = true
      }
    },
    hideCallModel() {
      this.incomingCallMessagePayload = undefined
      this.callModalOpen = false
    },
    hideAudioVideoSelector() {
      this.deviceSelctionOpen = false
    },
    showAudioVideoSelector(data) {
      this.deviceSelectionOnlyForAudio = !!data.onlyForAudio
      this.deviceSelctionOpen = true
    },
    getCurrentStudentCharacter() {
      useJwt.getStudentCharacter().then(res => {
        if (res.data.data.character) {
          this.$store.commit('appConfig/UPDATE_SELECTED_CHARACTER', res.data.data.character.toLowerCase())
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },

    getSchoolSettings() {
      getClassroomSettings({ getSchoolAndDistrict: true }).then(({ school, district }) => {
        const keys = ['game', 'avatar', 'attention']
        const settings = engagementSettings(keys, school, district)
        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
  },
}
</script>
