<template>
  <div class="note-form-container">
    <b-form v-if="isAStudent"
            class="mb-2"
            @submit.prevent="submitNote"
    >
      <b-form-group>
        <b-form-textarea
          v-model="noteContent"
          :placeholder="$t('type-note')"
          rows="3"
          max-rows="6"
          class="note-textarea"
          autofocus
          trim
        />
      </b-form-group>

      <div class="d-flex justify-content-between align-items-center">
        <small class="text-muted">
          {{ characterCount }}/500 {{ $t('characters') }}
        </small>
        <b-button
          type="submit"
          variant="primary"
          :disabled="!noteContent.trim()"
          class="submit-button"
        >
          <b-spinner v-if="isSubmitting"
                     small
          />
          <span v-else>{{ $t('submit') }}</span>
        </b-button>
      </div>
    </b-form>
    <GroupView
      ref="groupViewRef"
      :class-id="props.classId"
      :lesson-id="props.lessonId"
    />
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'
import GroupView from './GroupView.vue'

const props = defineProps({
  lessonId: {
    type: Number,
    default: 0,
  },
  classId: {
    type: Number,
    default: 0,
  },
})

const root = getCurrentInstance().proxy.$root
const noteContent = ref('')
const isSubmitting = ref(false)
const groupViewRef = ref(null)
const characterCount = computed(() => noteContent.value.length)
const isAStudent = computed(() => getUserData().usertype === USER_TYPE_STUDENT)
const submitNote = async () => {
  if (!noteContent.value.trim()) return

  try {
    isSubmitting.value = true
    useJwt.createNote({
      title: 'lesson-notes-paired',
      content: noteContent.value?.trim() ? noteContent.value : '&nbsp;',
      lesson_id: props.lessonId,
      classId: props.classId,
      type: 'paired',
    }).then(response => {
      noteContent.value = ''
      console.log(response)
      // Refresh GroupView after successful submission
      if (groupViewRef.value) {
        groupViewRef.value.fetchNotes()
      }
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isSubmitting.value = false
    })
  } catch (error) {
    console.error('Error submitting note:', error)
  } finally {
    isSubmitting.value = false
  }
}
</script>

  <style scoped>

  .note-textarea {
    border: 2px solid #e9ecef;
    border-radius: 6px;
    transition: border-color 0.2s ease-in-out;
  }

  .note-textarea:focus {
    border-color: #3490dc;
    box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
  }

  .submit-button {
    min-width: 100px;
    position: relative;
  }

  @media (max-width: 576px) {
    .note-form-container {
      padding: 1rem;
    }
  }
  </style>
