<template>
  <b-card style="  background: transparent !important;">
    <div class="row p-2 d-flex justify-content-center text-center">
      <div
        class="col-md-2"
        @dragover.prevent
        @dragenter.prevent
      >
        <ul class="list-group">
          <li v-for="point,index of statements"
              :key="index"
              class="list-group-item mt-1 bg-transparent"
          >
            <h3
              v-text-to-speech
              style="color: black"
              v-html="point.text"
            />
            <div class="pt-1">
              <audio-player v-if="point.statement_audio"
                            :key="point.statement_audio"
                            :src="point.statement_audio"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-7 hot-spot-image">
        <img :src="imageSrc"
             alt="Card Image"
             style="width: 100%;height: 100%;"
        >

        <div v-for="point,pKey of points"
             :key="pKey"
        >
          <template v-if="point">
            <div :set="ans = answers.find(item => answeredQuestion[point.hotspot.questions[0].id] == item.id)">
              <div
                :class="{
                  clickable: !point.statement_visited,
                  'not-clickable': point.statement_visited,
                  'hide-focus': !!ans
                }"
                class="point"
                :style="`top: ${point.coordinates.y * 100}%; left: ${
                  point.coordinates.x * 100
                }%; `"
                @click="point.is_clicked = true"
              >
                <div :id="`questionDragContain-${point.hotspot.questions[0].id}`"
                     class="content-answer"
                     :class="getAnsColor(ans)"
                     @drop="handleDrop($event, point.hotspot.questions[0].id)"
                     @dragover.prevent
                     @dragenter.prevent
                     @click="handleAnswerClick(point)"
                     @dblclick="removeAnswer(point.hotspot.questions[0].id)"
                >
                  <span v-if="ans"
                        :draggable="true"
                        @dragstart="handleAnsDragStart($event, point.hotspot.questions[0].id)"
                  >
                    <span v-if="ans.data_type == 'image'">
                      <img :src="ans.file"
                           style="width: 100%;"
                      >
                    </span>
                    <span v-if="ans.data_type === 'audio'">
                      <audio-player :key="ans.file"
                                    :src="ans.file"
                      />
                    </span>
                    <span v-else
                          class="ans-text"
                    >
                      {{ ans.text }}
                    </span>
                  </span>
                  <b-popover v-if="ans && pointIdToShowFeedback === point.id && !!getAnsColor(ans)"
                             :show="true"
                             :target="`questionDragContain-${point.hotspot.questions[0].id}`"
                             placement="bottom"
                  >
                    <span
                      v-text-to-speech
                      style="color: black"
                      v-html="point.hotspot.feedback"
                    />
                    <audio-player v-if="point.hotspot.feedback_audio"
                                  :key="point.hotspot.feedback_audio"
                                  :src="point.hotspot.feedback_audio"
                    />
                  </b-popover>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="ansSplits.length"
           class="col-md-2 border-dotted answers-lists answer-list-area  offset-md-1 ml-0"
           @dragover.prevent
           @dragenter.prevent
           @drop="undoAnswer($event)"
           @touchstart.stop="undoAnswer($event)"
      >
        <template v-for="ans,index of ansSplits">
          <div
            v-if="!isAnswered(ans.id)"
            :key="index"
            :draggable="true"
            style="width: fit-content;margin: auto;"
            role="button"
            :data-id="ans.questionId"
            @touchstart="startTouch(index)"
            @dblclick="handleDoubleClick(ans)"
            @dragstart="startDrag($event, ans.id)"
          >
            <span v-if="ans.data_type === 'image'"
                  class="ans-content"
            >
              <img :src="ans.file"
                   style="width: 100%;"
              >
            </span>
            <span v-if="ans.data_type === 'audio'">
              <audio-player :key="ans.file"
                            :src="ans.file"
              />
            </span>
            <span v-else
                  class="ans-content"
            >
              {{ ans.text }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </b-card>
</template>

<script>
// Import required Bootstrap Vue components
import { BCard, BPopover } from 'bootstrap-vue'
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'

export default {
  components: {
    // Register imported components
    BCard,
    BPopover,
    AudioPlayer,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    imageSrc: String,
    // eslint-disable-next-line vue/require-default-prop
    points: Array,
    previewAnswers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      answeredQuestion: {},
      ansCorrectness: [],
      pointIdToShowFeedback: 0,
    }
  },
  computed: {
    ansSplits() {
      return this.answers
    },
    statements() {
      const statement = []
      this.points.forEach(item => {
        if (!statement.find(s => s.text === item.hotspot?.statement)) {
          if (item.hotspot?.statement && item.hotspot?.statement.trim()) {
            statement.push({
              text: item.hotspot?.statement,
              statement_audio: item.hotspot?.statement_audio,
            })
          }
        }
      })
      return statement
    },
    answers() {
      const answers = []
      this.points.forEach(point => {
        const question = point.hotspot.questions[0]
        question.answers.forEach(ans => {
          const givenAnswer = this.ansCorrectness.find(item => item.input === ans.id)
          answers.push({
            id: ans.id,
            text: ans.text,
            data_type: ans.data_type,
            file: ans.file,
            isCorrect: givenAnswer ? !!givenAnswer.is_correct : undefined,
            questionId: question.id,
          })
        })
      })
      answers.sort(() => Math.random() - 0.5)
      return answers
    },
  },
  watch: {
    previewAnswers: {
      handler(value) {
        if (value && value.length) {
          const previewAnswer = {}
          value.forEach(item => {
            previewAnswer[item.question] = item.input
          })
          this.ansCorrectness = value
          this.answeredQuestion = previewAnswer
        }
      },
      immediate: true,
      deep: true,
    },
    answeredQuestion(value) {
      this.$emit('ansUpdate', {
        answers: value,
        bool: Object.values(value).length === this.points.length,
      })
    },
  },
  methods: {
    handleAnswerClick(point) {
      this.pointIdToShowFeedback = point.id
    },
    isAnswered(ansId) {
      // eslint-disable-next-line eqeqeq
      return !!Object.values(this.answeredQuestion).find(a => a == ansId)
    },
    removeAnswer(key) {
      delete this.answeredQuestion[key]
      this.$forceUpdate()
    },
    getAnsColor(answer) {
      switch (answer?.isCorrect) {
        case true:
          return 'border-success'
        case false:
          return 'border-danger'
        default:
          return ''
      }
    },
    enableFeedBack(input) {
      this.ansCorrectness = input
    },
    handleDrop(evt, questionId) {
      if (evt.preventDefault) { evt.preventDefault() }
      if (evt.stopPropagation) { evt.stopPropagation() }
      this.answeredQuestion = {
        ...this.answeredQuestion,
        [questionId]: Number(evt.dataTransfer.getData('itemID')),
      }
    },
    handleDoubleClick(ans) {
      const allQuestion = this.answers.map(i => i.questionId)
      const answeredQuestion = Object.keys({ ...this.answeredQuestion })
      const questionToAnswer = allQuestion.find(i => !answeredQuestion.includes(i.toString()))
      this.answeredQuestion = {
        ...this.answeredQuestion,
        [questionToAnswer]: Number(ans.id),
      }
    },
    startDrag(evt, ansID) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', ansID)
    },
    handleAnsDragStart(evt, questId) {
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.dropEffect = 'move'
      // eslint-disable-next-line no-param-reassign
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', questId)
    },
    undoAnswer(ev) {
      const questionId = ev.dataTransfer.getData('itemID')
      if (questionId) {
        delete this.answeredQuestion[questionId]
        this.$forceUpdate()
      }
    },
  },
}
</script>
<style>
.border-dotted {
  border: 1px dotted rgba(107, 103, 103, 0.573);
}
.answers-lists {
  text-align: center;
  padding-top: 30px;
}
.answers-lists div {
  line-height: 2;
  font-size: 16px;
  font-weight: 600;
}
.answers-lists div:hover {
  border: 1px dotted rgba(107, 103, 103, 0.573);
}

.content-answer {
  height: 50px;
  width: 100px;
  text-align: center;
  padding-top: 2px;
  position: absolute;
}

.hide-focus {
  border: none !important;
  background: none !important;
}
.hide-focus .ans-text {
  position: absolute;
  margin-top: 10px;
  margin-left: -20px;
  background-color: #ffffffc4;
  padding: 2px;
}
.popover {
    background-color: #ffffff9c;
    /* Add any other styles you want for the background */
}

.hot-spot-image {
  max-height: 600px;
  max-width: 600px !important;
  margin: auto !important;
  padding: 0px !important;
}
.bg-transparent {
  background: transparent !important;
}
</style>
