/* eslint-disable */
export default [
  {
    locale: 'en',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/en.png'),
    name: 'English',
    value: 'english',
  },
  // {
  //   locale: 'es',
  //   // eslint-disable-next-line global-require
  //   img: require('@/assets/images/flags/es.png'),
  //   name: 'Español',
  //   value: 'español',
  // },
  {
    locale: 'kr',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/kr.png'),
    name: '한국어',
    value: 'korean',
  },

  {
    locale: 'jp',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/jp.png'),
    name: '日本語',
    value: 'japanese',
  },
  {
    locale: 'vn',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/vn.png'),
    name: 'Tiếng Việt',
    value: 'vietnamese',
  },
  {
    locale: 'in',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/in.png'),
    name: 'हिन्दी, हिंदी',
    value: 'hindi',
  },
  {
    locale: 'cn',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/cn.png'),
    name: '中国人',
    value: 'chinese',
  },
  {
    locale: 'tr',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/turkey.png'),
    name: 'Türkiye',
    value: 'turkish',
  },
  {
    locale: 'my',
    // eslint-disable-next-line global-require
    img: require('@/assets/images/flags/my.png'),
    name: 'Malaysia',
    value: 'malaysia',
  },
]
