<template>
  <div>
    <OthersNotes v-if="!isTeacherView"
                 :notes="notes"
                 :lesson-id="props.lessonId"
                 :only-list="true"
                 @fetchNotes="fetchNotes"
    />
    <div v-else>
      <b-form-select
        v-model="selectedGroup"
        :options="groupsWithNotes.map(g => ({value: g.id, text: g.group_name}))"
        class="mb-1"
      >
        <template #first>
          <b-form-select-option
            v-if="isTeacherView"
            :value="STUDENT_PERSONAL"
          >
            {{ $t('all-student-personal-note') }}
          </b-form-select-option>
          <b-form-select-option :value="null">
            {{ $t('all-groups') }}
          </b-form-select-option>
        </template>

      </b-form-select>

      <template v-if="selectedGroup == STUDENT_PERSONAL">
        <div class="group-container">
          <div class="group-notes">
            <OthersNotes
              :notes="filteredNotes"
              :lesson-id="props.lessonId"
              :only-list="true"
              @fetchNotes="fetchNotes"
            />
          </div>
        </div>
      </template>

      <template v-else-if="selectedGroup">
        <div class="group-container">
          <div class="group-header p-2 mb-2 bg-light rounded d-flex align-items-center">
            <i class="fas fa-users mr-2" />
            <h5 class="mb-0">
              {{ selectedGroupName }}
            </h5>
          </div>
          <div class="group-notes">
            <OthersNotes
              :notes="filteredNotes"
              :lesson-id="props.lessonId"
              :only-list="true"
              @fetchNotes="fetchNotes"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div v-for="group in groupsWithNotes"
             :key="group.id"
             class="group-container mb-4"
        >
          <div class="group-header p-2 mb-2 bg-light rounded d-flex align-items-center">
            <i class="fas fa-users mr-2" />
            <h5 class="mb-0">
              {{ group.group_name }}
            </h5>
          </div>
          <div class="group-notes">
            <OthersNotes
              :notes="filteredNotes.filter(note => group.members?.includes(note.student_id))"
              :lesson-id="props.lessonId"
              :only-list="true"
              @fetchNotes="fetchNotes"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import {
  defineExpose, onMounted, ref, computed,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import OthersNotes from '../OtherNotes.vue'

const STUDENT_PERSONAL = 'student_personal'
const notes = ref([])
const isTeacherView = ref(false)
const groupLists = ref([])
const selectedGroup = ref(STUDENT_PERSONAL)

const groupsWithNotes = computed(() => groupLists.value.filter(group => notes.value.some(note => group.members?.includes(note.student_id))))

const selectedGroupName = computed(() => {
  const group = groupLists.value.find(g => g.id === selectedGroup.value)
  return group?.group_name
})

const filteredNotes = computed(() => {
  if (!selectedGroup.value) {
    return notes.value.filter(note => note.type)
  }
  if (selectedGroup.value === STUDENT_PERSONAL) {
    return notes.value.filter(note => !note.type)
  }
  const group = groupLists.value.find(g => g.id === selectedGroup.value)
  return notes.value.filter(note => group?.members?.includes(note.student_id) && note.type)
})

const props = defineProps({
  lessonId: {
    type: Number,
    default: 0,
  },
  classId: {
    type: Number,
    default: 0,
  },
})

const fetchNotes = async () => {
  try {
    const response = await useJwt.getGroupNoteByLessonId(props.lessonId, {
      params: { class_id: props.classId },
    })
    notes.value = response.data.data.group_note
    isTeacherView.value = response.data.data.isTeacher
    groupLists.value = response.data.data.pairs
  } catch (error) {
    console.error('Error fetching group notes:', error)
  }
}

// Fetch notes on mount
onMounted(fetchNotes)

// Expose the function so `GroupNote.vue` can call it
defineExpose({ fetchNotes })

</script>
