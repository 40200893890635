import axios from 'axios'
import { GEC_ML_END_POINT } from '@/const/chatbot'

const streamAudio = (text, endpoint = '/api/tts-ai-version', onPlaying = null, onComplete = null) => {
  const audioStream = new Audio(`${endpoint}?text=${text}`)
  audioStream
    .play()
    .then(() => {
      if (typeof onPlaying === 'function') {
        onPlaying() // Call the provided callback when audio starts playing
      }
    })
    .catch(error => console.error('Error playing audio:', error))
  audioStream.addEventListener('ended', () => {
    if (typeof onComplete === 'function') {
      onComplete(true) // Call the provided callback after audio ends
    }
  })
  return {
    audioStream,
    stop() {
      audioStream.pause() // Stop the audio playback
      audioStream.currentTime = 0 // Reset playback to the beginning
    },
  }
}

const streamText = async (params, onStream) => {
  // eslint-disable-next-line no-param-reassign
  params.output = 'text'
  try {
    const response = await fetch(`${GEC_ML_END_POINT}/common/tts/stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })

    if (!response.body) {
      throw new Error('ReadableStream not supported or empty response.')
    }

    const reader = response.body.getReader()
    const decoder = new TextDecoder()

    let streamedText = ''
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read()
      if (done) break

      const textChunk = decoder.decode(value, { stream: true })
      streamedText += textChunk
      onStream(textChunk)
    }

    return {
      success: true,
      text: streamedText,
    }
  } catch (error) {
    return {
      success: false,
      error,
    }
  }
}

const streamAudioFromGecMl = async ({
  text, mainPoint, language, voice, history, output = 'audio', modeId = null,
}, onPlaying = null, onComplete = null) => {
  try {
    const endpoint = `${GEC_ML_END_POINT}/common/tts/stream`
    const getCacheKey = await axios.post(endpoint, {
      prompt: '-',
      input: text,
      main_points: mainPoint,
      language,
      voice,
      history,
      output,
      modeId,
    })
    const cacheKey = getCacheKey.data.data.cache_key
    // TODO: Handle Stream Error
    const audioStream = new Audio(`${endpoint}?prompt=${cacheKey}`)
    audioStream
      .play()
      .then(() => {
        if (typeof onPlaying === 'function') {
          onPlaying() // Call the provided callback when audio starts playing
        }
      })
      .catch(error => {
        if (typeof onComplete === 'function') {
          onComplete({
            success: false,
            error,
            cacheKey,
          })
        }
        throw error
      })
    audioStream.addEventListener('ended', () => {
      if (typeof onComplete === 'function') {
        onComplete({
          success: true,
          error: null,
          cacheKey,
        }) // Call the provided callback after audio ends
      }
    })
    return {
      audioStream,
      stop() {
        audioStream.pause() // Stop the audio  playback
        audioStream.currentTime = 0 // Reset playback to the beginning
      },
    }
  } catch (error) {
    console.error(error)
    onComplete({
      success: false,
      error,
    })
    return null
  }
}

const streamBaseAudio = async ({
  input, prompt, language, voice, history = [],
}, onPlaying = null, onComplete = null) => {
  try {
    const endpoint = `${GEC_ML_END_POINT}/common/tts/stream`
    const getCacheKey = await axios.post(endpoint, {
      prompt: prompt || '-',
      input,
      language,
      voice,
      history,
    })
    const cacheKey = getCacheKey.data.data.cache_key
    const audioStream = new Audio(`${endpoint}?prompt=${cacheKey}`)
    audioStream
      .play()
      .then(() => {
        if (typeof onPlaying === 'function') {
          onPlaying() // Call the provided callback when audio starts playing
        }
      })
      .catch(error => { throw new Error(error) })
    audioStream.addEventListener('ended', () => {
      if (typeof onComplete === 'function') {
        onComplete({
          success: true,
          error: null,
          cacheKey,
        }) // Call the provided callback after audio ends
      }
    })
    return {
      audioStream,
      stop() {
        audioStream.pause() // Stop the audio  playback
        audioStream.currentTime = 0 // Reset playback to the beginning
      },
    }
  } catch (error) {
    console.error(error)
    onComplete({
      success: false,
      error,
    })
    return null
  }
}

const voiceStream = async ({
  text, voice,
}, onPlaying = null, onComplete = null) => {
  try {
    const response = await fetch(`${GEC_ML_END_POINT}/common/text-to-audio/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text,
        voice,
      }),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred. Please try again.')
    }

    const audioBlob = await response.blob()
    const audioURL = URL.createObjectURL(audioBlob)
    const audioStream = new Audio(audioURL)

    audioStream
      .play()
      .then(() => {
        if (typeof onPlaying === 'function') {
          onPlaying()
        }
      })
      .catch(error => { throw new Error(error) })

    audioStream.addEventListener('ended', () => {
      if (typeof onComplete === 'function') {
        onComplete({
          success: true,
          error: null,
        })
      }
      URL.revokeObjectURL(audioURL) // Clean up the blob URL after playback
    })

    return {
      audioStream,
      stop() {
        audioStream.pause()
        audioStream.currentTime = 0
        URL.revokeObjectURL(audioURL)
      },
    }
  } catch (error) {
    console.error(error)
    if (typeof onComplete === 'function') {
      onComplete({
        success: false,
        error,
      })
    }
    return null
  }
}

export {
  streamText,
  voiceStream,
  streamAudio,
  streamBaseAudio,
  streamAudioFromGecMl,
}
