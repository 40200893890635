<template>
  <div>
    <div :id="translateDiv" />
    <div class="skills-container px-2 mb-4">

      <h2 class="translate">
        {{ $t('chat-page.enabling-skills') }}
      </h2>

      <div class="skills-wrapper position-relative">
        <div class="progress-container">
          <div v-for="(skill, index) in allSkills.skills"
               :key="index"
               class="skill-row"
          >
            <div class="d-flex align-items-center">
              <div class="skill-label">
                <b-button
                  :id="`skill-info-${index}`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="link"
                  class="info-icon mr-2"
                  @click="skill.readMore = !skill.readMore"
                >
                  <feather-icon icon="AlertCircleIcon" />
                </b-button>

                <b-popover
                  :target="`skill-info-${index}`"
                  placement="top"
                  triggers="click"
                  :show.sync="skill.readMore"
                  @hide="skill.readMore = false"
                >
                  <template #title>
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="translate">{{ skill.label }} Details</span>
                      <b-button
                        variant="link"
                        class="close p-0"
                        @click="$root.$emit('bv::hide::popover')"
                      >
                        <feather-icon icon="XIcon"
                                      size="16"
                        />
                      </b-button>
                    </div>
                  </template>
                  <div :id="`desc-skill-${index}`"
                       class="translate"
                  >
                    <b-button
                      variant="link"
                      class="play-btn mr-2"
                      @click="playDescription(skill, index)"
                    >
                      <feather-icon
                        :icon="isPlaying ? 'StopCircleIcon' : 'PlayIcon'"
                        size="16"
                      />
                    </b-button>  {{ skill.description }}
                  </div>
                </b-popover>

                <span class="translate">{{ skill.label }}</span>
                <span class="score translate">{{ getAvg(skill) }}</span>
              </div>
              <div class="progress flex-grow-1">
                <div
                  class="progress-bar"
                  :style="{
                    width: getAvg(skill) + '%',
                    backgroundColor: getColorForScore(getAvg(skill))
                  }"
                  role="progressbar"
                  :aria-valuenow="skill.score"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import setUpGoogleTranslate from '@/utils/googleTranslate'
import { voiceStream } from '@/views/AiDemo/demobot/helpers/streamAudio'

export default {
  name: 'SkillsProgressComponent',
  components: {
    BButton,
    BPopover,
  },
  directives: {
    Ripple,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      translateDiv: `gt-${Math.random().toString(36).substring(2, 15)}`,
      isPlaying: false,
      currentPlayingIndex: null,
      audioStopper: null,
    }
  },
  computed: {
    allSkills() {
      const skillsWithReadMore = this.skills.map(i => ({ ...i, readMore: false }))
      // const secondLastItem = skillsWithReadMore.splice(skillsWithReadMore.length - 2, 1)[0]
      // skillsWithReadMore.push(secondLastItem)
      return {
        skills: skillsWithReadMore,
      }
    },
  },
  mounted() {
    document.body.classList.add('notranslate')
    const elements = document.querySelectorAll('[translate]')
    elements.forEach(el => {
      el.setAttribute('translate', 'no')
    })
    setUpGoogleTranslate(this.translateDiv)
  },
  beforeDestroy() {
    if (this.audioStopper) {
      this.audioStopper()
    }
  },
  methods: {
    getAvg(score) {
      return Number((score.score / score.total_score) * 100).toFixed(2)
    },
    getColorForScore(score) {
      if (score >= 80) return '#28a745' // Dark green for excellent scores
      if (score >= 70) return '#34c759' // Green for very good scores
      if (score >= 60) return '#40d97d' // Light green for good scores
      if (score >= 50) return '#5ce498' // Very light green for average scores
      if (score >= 40) return '#ff9f40' // Orange for below average scores
      return '#ff6b6b' // Red for low scores
    },
    async playDescription(skill, index) {
      if (this.isPlaying) {
        // Stop current audio
        if (this.audioStopper) {
          this.audioStopper()
          this.isPlaying = false
          this.currentPlayingIndex = null
          this.audioStopper = null
        }
      } else {
        // Stop previous audio if playing
        if (this.audioStopper) {
          this.audioStopper()
        }
        this.isPlaying = true
        this.currentPlayingIndex = index
        try {
          // Play new audio
          const { stop } = await voiceStream({
            text: document.getElementById(`desc-skill-${index}`).textContent,
            voice: 'onyx',
          }, () => {
            this.isPlaying = true
          }, ({ success, error }) => {
            if (!success) {
              console.error('Error playing audio:', error)
            }
            this.isPlaying = false
            this.currentPlayingIndex = null
            this.audioStopper = null
          })
          this.audioStopper = stop
        } catch (e) {
          console.error('Error playing audio:', e)
        }
      }
    },
  },
}
</script>

<style scoped>
.skills-container {
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto;
}

.progress-container {
  position: relative;
  padding-left: 220px; /* Width of skill-label (200px) + gap (20px) */
}

.skill-row {
  margin-bottom: 15px;
  position: relative;
}

.skill-row:last-child {
  margin-bottom: 0;
}

.skill-row .d-flex {
  gap: 20px;
}

.skill-label {
  width: 200px;
  font-size: 1.1rem;
  color: #666;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  left: -220px; /* Width of skill-label (200px) + gap (20px) */
}

.info-icon, .play-btn {
  color: #6c757d;
  padding: 0;
  margin-right: 8px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-icon:focus, .play-btn:focus {
  box-shadow: none !important;
}

.score {
  color: #2c3e50;
  font-weight: bold;
  margin-left: 8px;
}

.progress {
  background-color: #fff;
  border-radius: 0;
  position: relative;
  height: 30px;
  overflow: hidden;
}

.progress-bar {
  transition: all 0.6s ease;
}

.vertical-line-continuous {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 8px;
  background-color: #6c757d91;
  z-index: 2;
  height: 100%;
  pointer-events: auto;
  cursor: help;
  transition: background-color 0.3s ease;
  transform: translateX(-4px);
  left: 0;
}

.vertical-line-continuous:hover {
  background-color: #6c757d;
}

.skills-wrapper {
  margin-top: 20px;
}
.skill-row .translate {
  font-size: 14px !important;
  mix-blend-mode: difference;
  color: white;
}
</style>
