/* eslint-disable import/no-cycle */
import districtUsersOnly from '@/middlewares/districtUsersOnly'
import isAuthenticated from '@/middlewares/isAuthenticated'

export default [
  // {
  //   path: '/district/home',
  //   meta: {
  //     middleware: [isAuthenticated, districtUsersOnly],
  //   },
  //   name: 'district-home',
  //   component: () => import('@/views/district/Home.vue'),
  // },
  {
    path: '/district/users',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    name: 'district-users',
    component: () => import('@/views/common/user/Index.vue'),
  },
  {
    path: '/district/networks',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    name: 'district-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/district/networks/:id',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-networks',
    },
    name: 'district-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/district/classes',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    name: 'district-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/district/classes/:classId/calendar',
    name: 'district-class-calendar',
    meta: {
      layout: 'classRoom',
      isRouterView: true,
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    component: () => import('@/views/teacher/class/components/calendar/Calendar.vue'),
  },
  {
    path: '/district/classes/:id/:courseId?',
    name: 'district-class',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/district/student-diary/:id',
    name: 'district-student-diary',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/district/courses/:id',
    name: 'district-course',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/district/view-students/:id',
    name: 'district-view-students',
    meta: {
      layout: 'classRoom',
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/district/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    name: 'student-dashboard-for-district',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/district/higher-level-report',
    name: 'district-higher-level-report',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/district/higher-level-report',
    name: 'district-higher-level-report',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/district/aggregates-report',
    name: 'district-aggregates-report',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/detail-report.vue'),
  },
  {
    path: '/district/individual-report/:studentId',
    name: 'district-individual-report',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/district/class/:classId/course/:id/lessons',
    meta: {
      layout: 'classRoom',
      isRouterView: true,
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    name: 'district-lessons',
    component: () => import('@/views/student/lesson/Index.vue'),
  },
  {
    path: '/district/class/:classId/course/:id/result',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    name: 'district-course-result',
    component: () => import('@/views/student/course/Result.vue'),
  },
  {
    path: '/district/class/:classId/lesson/:id',
    meta: {
      layout: 'classRoom',
      isRouterView: true,
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    name: 'district-lesson',
    component: () => import('@/views/student/lesson/Show.vue'),
  },
  {
    path: '/district/class/:classId/lesson/:id/problems',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-classes',
    },
    name: 'district-problems',
    component: () => import('@/views/student/problem/Index1.vue'),
  },
  {
    path: '/district/view-attendance/:id?',
    name: 'district-view-attendance',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
      navActiveLink: 'district-view-attendance',
    },
    component: () => import('@/views/student/attendance/index.vue'),
  },
  {
    path: '/district/class-test/:classId',
    name: 'district-class-test',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/student/lesson/ClassTest/index.vue'),
  },
  {
    path: '/district/partner-services',
    name: 'district-partner-services',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/settings/PartnerSetting.vue'),
  },
  {
    path: '/district/settings',
    name: 'district-settings',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/settings/Settings.vue'),
  },
  {
    path: '/district/library',
    name: 'district-library',
    meta: {
      middleware: [isAuthenticated, districtUsersOnly],
    },
    component: () => import('@/views/district/library/Index.vue'),
  },
]
