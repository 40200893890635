<template>
  <span v-if="scores.length > 0"
        class="dialogs"
        role="button"
  >
    <b-badge variant="primary"
             class="mb-2 report-badge align-items-center"
             @click="showModal = !showModal"
    >
      <feather-icon
        icon="FileTextIcon"
        size="16"
        class="mr-1"
      />
      {{ $t('chat-page.report') }}
      <feather-icon
        :icon="showModal ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="16"
        class="ml-1"
      />
    </b-badge>

    <b-collapse v-model="showModal">
      <b-card class="report-card">
        <b-overlay :show="isDataTranslating">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="BarChartIcon"
              size="18"
              class="mr-1"
            />
            <h5 class="m-0 text-capitalize translate">
              {{ $t('chat-page.report') }}
            </h5>
          </div>
          <ScoreGraph :skills="scores"
                      :lang="selectedLanguage"
          />
          <div class="ml-2">
            <div class="d-flex align-items-center mb-2">
              <feather-icon
                icon="ListIcon"
                size="18"
                class="mr-1"
              />
              <h4 class="translate mb-0">
                {{ $t('chat-page.sentence-count') }}
              </h4>
            </div>
            <div class="row">
              <div class="col-6">
                <span class="text-dark translate">{{ $t('chat-page.total-sentence') }}:</span>
                <span class="text-dark ml-2">{{ countedSentencesAndWords.sentenceCount }}</span>
              </div>
              <div class="col-6">
                <ul class="sentence-list">
                  <li v-for="word,index of countedSentencesAndWords.wordCounts"
                      :key="index"
                      class="text-dark"
                  >
                    <span class="translate">{{ $t('chat-page.sentence') }} {{ index + 1 }} :- {{ word }} Words</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-overlay>
      </b-card>
    </b-collapse>

    <div v-if="isATeacher">
      <b-button variant="gradient-primary"
                :disabled="isProcessing"
                class="mt-2 regenerate-btn"
                @click="saveOpenAiScore"
      >
        <b-spinner v-if="isProcessing"
                   small
        />
        <template v-else>
          <feather-icon
            icon="RefreshCwIcon"
            size="16"
            class="mr-1"
          />
        </template>
        {{ $t('chat-page.regenerate-ai-score') }}
      </b-button>
    </div>
  </span>
</template>

<script>
import {
  BButton,
  BSpinner,
  BBadge,
  BOverlay,
  BCollapse,
  BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ScoreGraph from './ScoreGraph.vue'

export default {
  name: 'ReportCollapse',

  components: {
    BButton,
    BSpinner,
    BBadge,
    BOverlay,
    BCollapse,
    BCard,
    ScoreGraph,
  },

  props: {
    scores: {
      type: Array,
      default: () => [],
    },
    answer: {
      type: String,
      default: '',
    },
    testId: {
      type: Number,
      default: 0,
    },
    hasTeacherScored: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      isProcessing: false,
      selectedLanguage: this.language,
      isDataTranslating: false,
    }
  },

  computed: {
    isATeacher() {
      return ['teacher', 'super'].includes(this.AUTH_USER().usertype)
    },

    getTotalScore() {
      if (!this.scores.length) {
        return 0
      }
      const scoreCount = this.scores.reduce((c, p) => c + Number(p.score), 0)
      return (scoreCount / (10 * this.scores.length)) * 10
    },

    countedSentencesAndWords() {
      const text = this.answer
      // Count the number of sentences
      const sentences = text.split(/[.!?]+/g).filter(Boolean)
      const sentenceCount = sentences.length

      // Count the number of words in each sentence
      const wordCounts = sentences.map(sentence => {
        const words = sentence.trim().split(/\s+/)
        return words.length
      })

      return {
        sentenceCount,
        wordCounts,
      }
    },

    language() {
      return localStorage.getItem('locale') || 'en'
    },
  },

  methods: {
    saveOpenAiScore() {
      this.isProcessing = true
      useJwt.updateOpenAiScore(this.testId)
        .then(response => {
          const openAiScore = response.data.data.open_ai_score
          if (openAiScore.length === 0) {
            this.$emit('show-info', 'Calculation was not successful. Please try again')
          } else {
            this.$emit('show-success', response)
            this.$emit('refetch', response.data.data.open_ai_score, response.data.data.test_stat.score)
            this.$root.$emit('reFetchStudentLessonStatReport')
            this.$forceUpdate()
          }
        })
        .catch(error => {
          this.$emit('show-error', error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dialogs {
  display: block;

  .report-badge {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    font-size: 0.95rem;
    transition: all 0.25s ease;
    background: linear-gradient(45deg, #7367f0, #9e95f5);
    border: none;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 8px rgba(115, 103, 240, 0.35);
    }

    svg {
      stroke-width: 2.5px;
    }
  }

  .report-card {
    border-radius: 0.5rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    transition: all 0.3s ease-in-out;
    border: none;

    &:hover {
      box-shadow: 0 4px 28px 0 rgba(34, 41, 47, 0.15);
    }
  }

  .sentence-list {
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .regenerate-btn {
    display: inline-flex;
    align-items: center;
    padding: 0.6rem 1.2rem;
    font-weight: 500;
    background: linear-gradient(45deg, #7367f0, #9e95f5);
    border: none;
    transition: all 0.25s ease;

    svg {
      stroke-width: 2.5px;
    }

    &:hover:not(:disabled) {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(115, 103, 240, 0.4);
      background: linear-gradient(45deg, #6355ee, #8f84f3);
    }

    &:active:not(:disabled) {
      transform: translateY(0);
      box-shadow: 0 2px 8px rgba(115, 103, 240, 0.4);
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  .text-dark {
    color: #4b4b4b;
  }

  .translate {
    font-weight: 500;
  }
}

// Collapse animations
.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.3s ease-in-out;
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.collapse-enter,
.collapse-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
