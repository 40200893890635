import i18n from '@/libs/i18n'

export const problemTags = [
  {
    id: 'grammar',
    text: i18n.tc('grammar'),
  },
  {
    id: 'vocabulary',
    text: i18n.tc('vocabulary'),
  },
]

export const userTags = [
  {
    value: null,
    text: i18n.tc('select-tag'),
  },
  {
    value: 'sponsor',
    text: i18n.tc('network-member-types.sponsor'),
  },
  {
    value: 'charity',
    text: i18n.tc('network-member-types.charities'),
  },
]

export const calendarEventTags = [
  {
    text: 'Self Study',
    value: 'self_study',
  },
  {
    text: 'Exploration',
    value: 'exploration',
  },
  {
    text: 'Auto Assigned',
    value: 'auto-assigned',
  },
  {
    text: 'Problem Bank',
    value: 'problem_bank',
  },
]

export const getTagLabel = tag => calendarEventTags.find(i => i.value === tag)?.text || tag
export const calendarEventFilterTags = [
  {
    text: 'Self Study',
    value: 'self_study',
  },
  {
    text: 'Exploration',
    value: 'exploration',
  },
]
