<template>
  <dialog-drag
    :style="`width:${youtubeVideoWidth}px; height:${youtubeVideoHeight}px;}`"
    class="youtube-dialog"
    :options="{top:0,left:0}"
    @close="$emit('close')"
  >
    <div class="header justify-content-between d-flex bg-success p-1">
      <div class="title">
        <h5 class="text-white">
          Video
        </h5>
      </div>
      <div class="icons">
        <feather-icon size="20"
                      class="text-white cursor-pointer"
                      :icon="`${youtubeMaximized ? 'MinimizeIcon' : 'MaximizeIcon'}`"
                      @click="maximizeMinimizeYoutube"
        />
        <feather-icon size="22"
                      class="text-white cursor-pointer ml-2"
                      icon="XIcon"
                      @click="$emit('close')"
        />
      </div>
    </div>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe :width="youtubeVideoWidth"
              :height="youtubeVideoHeight-50"
              :src="youtubeVideoLink"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
      />
    </div>
  </dialog-drag>
</template>
<script>
import DialogDrag from 'vue-dialog-drag'

export default {
  components: {
    DialogDrag,
  },
  props: {
    youtubeVideoLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      youtubeMaximized: false,
      youtubeVideoHeight: 315,
      youtubeVideoWidth: 560,
    }
  },
  methods: {
    maximizeMinimizeYoutube() {
      if (this.youtubeMaximized) {
        this.youtubeVideoWidth = 560
        this.youtubeVideoHeight = 315
        this.youtubeMaximized = false
        return
      }
      this.youtubeVideoWidth = window.innerWidth
      this.youtubeVideoHeight = window.innerHeight
      this.youtubeMaximized = true
    },
  },
}
</script>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
<style>
  .youtube-dialog{
    border:none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    .dialog-header{
      display:none !important;
    }
  }
</style>
