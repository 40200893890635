var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},_vm._l((_vm.data),function(card,index){return _c('div',{key:index,staticClass:"d-flex align-items-stretch",class:{
      'col-md-3': _vm.data.length > 3,
      'col-md-4': _vm.data.length === 3,
      'col-md-6': _vm.data.length === 2,
      'col-md-12': _vm.data.length === 1,
    }},[_c('div',{staticClass:"card w-100"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},[(card.image_url)?_c('img',{directives:[{name:"image-preview",rawName:"v-image-preview"}],staticClass:"card-img-top",class:{
            'fixed-width': _vm.data.length <= 2,
          },staticStyle:{"margin":"0 auto","padding":"4px"},attrs:{"src":card.image_url}}):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('WordWithPopover',{attrs:{"element-class":['card-title mb-0 text-center'],"tag":"h5","text":`${(_vm.isAProblem ? _vm.formatQuestion(card.title,'title',index): card.title) || ''}`,"word-power-enabled":true,"meta-type":"generated_word"}}),(card.subtitle && card.subtitle !== 'Example')?_c('div',{staticClass:"text-center"},[_c('small',{staticClass:"text-center"},[_vm._v(" "+_vm._s(card.subtitle)+" ")])]):_vm._e()],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }