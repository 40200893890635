<template>
  <b-modal
    title="Lesson Preview"
    size="xl"
    :visible="show"
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="modal-title">
          Lesson Preview
        </h5>
        <div v-if="!isLinearLesson"
             class="lang-level-select mr-2 mb-2"
        >
          <b-button
            v-for="(label, index) in ['A1', 'A2', 'B1', 'B2']"
            :key="index"
            :variant="level === index + 1 ? 'success' : 'flat-success'"
            @click="level = index + 1"
          >
            {{ getLangLevelTransText(label) }}
          </b-button>
        </div>
        <div
          class="float-right d-flex align-items-center"
        >
          <b-form-select v-if="isEditMode"
                         v-model="selectedOption"
                         :options="options"
                         aria-label="Options"
          />
          <b-form-select
            v-model="selectedVoice"
            :options="voiceOptions"
            class="ml-2"
            aria-label="Voice Options"
          />
          <b-button variant="outline-secondary"
                    aria-label="Close"
                    class="ml-2"
                    @click="$emit('close')"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
      </div>
    </template>
    <iframe
      :src="previewLink"
      style="height: 100vh;width: 100%;"
      frameBorder="0"
    />
  </b-modal>
</template>
<script>
import {
  BModal,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormSelect,
    BButton,
  },
  props: {
    problemId: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    lessonId: {
      type: Number,
      required: true,
    },
    lessonInfo: {
      type: Object,
      default: () => {},
    },
    extraPayload: {
      type: Object,
      default: () => {},
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    problemsInTheGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOption: 'only_statement',
      options: [
        { value: 'only_statement', text: 'Problem Statement' },
        { value: 'only_feedback', text: 'Problem Feedback' },
        ...this.problemsInTheGroups.map(i => ({
          value: i.id,
          text: `${i.name} [${i.problem_type.name}]`,
        })),
      ],
      level: 1,
      tabs: [
        {
          value: '4',
          label: 'B2',
        },
        {
          value: '3',
          label: 'B1',
        },
        {
          value: '2',
          label: 'A2',
        },
        {
          value: '1',
          label: 'A1',
        },
      ],
      selectedVoice: 'female',
      voiceOptions: [
        {
          value: 'male',
          text: 'Male Voice',
        },
        {
          value: 'female',
          text: 'Female Voice',
        },
      ],
    }
  },
  computed: {
    isLinearLesson() {
      return !!this.lessonInfo?.is_linear
    },
    selectedProblemId() {
      if (Number.isInteger(this.selectedOption)) {
        return this.selectedOption
      }
      return null
    },
    previewLink() {
      const payload = {
        level: 'demo-2',
        lid: this.lessonId,
        problem_id: this.selectedProblemId || this.problemId,
        lang_level: this.isLinearLesson ? 0 : this.level,
        ...(this.isEditMode ? {
          editMode: this.selectedProblemId ? 0 : 1,
          layout: this.selectedProblemId ? 'only_body' : this.selectedOption,
          voice: this.selectedVoice,
        } : {}),
      }
      return `/${this.AUTH_USER().usertype}-demo-lesson?${new URLSearchParams(payload).toString()}`
    },
  },
}
</script>
