export default {
  namespaced: true,
  state: {
    activeStandard: null,
    canSaveConversationBuffer: localStorage.getItem('disableConversationBuffer') !== '1',
    activeMainPoints: [],
    evaluationHistory: [], // Added evaluation history state
    disableAssessmentBuffer: true,
    selectedReaPromptId: null,
    assessMentPromptId: null,
  },

  getters: {
    isEvaluationMode: state => state.evaluationHistory?.length > 0,
    isConversationBufferEnabled: state => !state.evaluationHistory?.length || !state.disableAssessmentBuffer,
    getEvaluationBuffer: state => state.evaluationHistory?.filter(i => i.role === 'assistant') || [],
    getRequiredPrompt: state => {
      if (state.evaluationHistory?.length > 0) {
        return state.assessMentPromptId
      }
      return state.selectedReaPromptId
    },
  },

  mutations: {
    SET_ACTIVE_STANDARD(state, standard) {
      state.activeStandard = standard
    },
    SET_ACTIVE_MAIN_POINTS(state, points) {
      state.activeMainPoints = points
    },
    SET_EVALUATION_HISTORY(state, history) {
      state.evaluationHistory = history
    },
    SET_CONVERSATION_BUFFER_TOGGLE(state, bool) {
      state.canSaveConversationBuffer = bool
    },
    SET_ASSESSMENT_BUFFER(state, bool) {
      state.disableAssessmentBuffer = bool
    },
    SET_SELECTED_REA_PROMPT_ID(state, val) {
      state.selectedReaPromptId = val
    },
    SET_ASSESSMENT_PROMPT(state, val) {
      state.assessMentPromptId = val
    },
  },

  actions: {
    setActiveStandard({ commit }, standard) {
      commit('SET_ACTIVE_STANDARD', standard)
    },
    setActiveMainPoints({ commit }, item) {
      commit('SET_ACTIVE_MAIN_POINTS', item)
    },
    setEvaluationHistory({ commit }, history) {
      commit('SET_EVALUATION_HISTORY', history)
    },
    setConversationBuffer({ commit }, bool) {
      localStorage.setItem('disableConversationBuffer', bool ? '0' : '1')
      commit('SET_CONVERSATION_BUFFER_TOGGLE', bool)
    },
    setAssessMentBuffer({ commit }, bool) {
      commit('SET_ASSESSMENT_BUFFER', bool)
    },
    setSelectedReaPrompt({ commit }, val) {
      commit('SET_SELECTED_REA_PROMPT_ID', val)
    },
    setAssessmentReaPrompt({ commit }, val) {
      commit('SET_ASSESSMENT_PROMPT', val)
    },
    appendEvaluationHistory({ commit, state }, payload) {
      console.log([
        ...state.evaluationHistory,
        ...payload,
      ])
      commit('SET_EVALUATION_HISTORY', [
        ...state.evaluationHistory,
        ...payload,
      ])
    },
  },
}
